import styled from "@emotion/styled";
import humanizeTime from "helpers/humanizeTime";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEventDelete } from "../../EventEdit/hooks/useEventDelete";
import { ActionIcon, Group, Loader, Stack, Text, Title } from "@mantine/core";
import { Event } from "api/interfaces/events";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";

// Props
type ComponentProps = {
  event: Event;
};

// Component
export default function EventItem({ event }: ComponentProps) {
  const { handleDelete, isLoading } = useEventDelete(event);
  const { route } = useMerchantNavigation();

  return (
    <Link to={`${route}/campaigns/${event.id}`}>
      <Root grow position="apart">
        <Group position="left">
          <Stack spacing={0}>
            <Title sx={{ wordBreak: "break-all" }} order={5}>
              {event.name}
            </Title>
            <Text size="sm" color={"dimmed"}>
              {event.description.slice(0, 50)}...
            </Text>
          </Stack>
        </Group>

        <Group position="right" spacing={"lg"}>
          <Group position="center">
            <Text size="sm" color={"dimmed"}>
              End in {humanizeTime(event.endDate)}
            </Text>
          </Group>

          <Group position="right" spacing={"xs"}>
            <ActionIcon
              variant="light"
              color={"teal"}
              size={"lg"}
              radius={"md"}
              style={{ border: "1px solid #cef3e8" }}
            >
              <Icon icon={"fluent:edit-24-filled"} color={"teal"} />
            </ActionIcon>
            <ActionIcon
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleDelete();
              }}
              variant="light"
              color={"red"}
              size={"lg"}
              radius={"md"}
              style={{ border: "1px solid #fbe7e7" }}
            >
              {isLoading ? (
                <Loader size="xs" sx={{ stroke: "red" }} />
              ) : (
                <Icon icon={"fluent:delete-24-filled"} />
              )}
            </ActionIcon>
          </Group>
        </Group>
      </Root>
    </Link>
  );
}

// Styling
const Root = styled(Group)`
  flex-direction: row;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
