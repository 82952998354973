import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Group, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "features/Auth/hooks/useAuth";
import ContentLoader from "components/ContentLoader";
import { useContent } from "features/ContentEdit/hooks/useContent";
import { useContentCreate } from "features/ContentEdit/hooks/useContentCreate";
import { useContentUpdate } from "features/ContentEdit/hooks/useContentUpdate";
import { useContentDelete } from "features/ContentEdit/hooks/useContentDelete";
import ContentEdit from "features/ContentEdit";
import { Content, ContentInput } from "api/interfaces/content";
import { ImageContent } from "api/interfaces/imageContents";
import { TextContent } from "api/interfaces/textContent";
import { IAccount } from "api/interfaces/account";
import { useAdmins } from "features/Admins/useAdmins";
import useTextContentList from "features/TextContentList/hooks/useTextContentList";
import useImageContentList from "features/ImageContentList/hooks/useImageContentList";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import {showNotification} from "@mantine/notifications";
import { useMerchantDetails } from 'features/MerchantProfile/hooks/useMerchantDetails';

// Validation Schema
const schema = yup
  .object({
    name: yup
      .string()
      .max(32, `Name is too long. Maximum length is 32 chars.`)
      .lowercase()
      .trim()
      .required("Required"),
    adminId: yup.number(),
    contentTypeId: yup.number().required("Required"),
    imageContentId: yup
      .string()
      .when("contentTypeId", {
        is: (value: number) => [2, 3, 4, 5].includes(value),
        then: yup.string().required("Required").nullable(),
        otherwise: yup.string().nullable(),
      })
      .trim(),
    textContentId: yup
      .string()
      .nullable(true)
      .when("contentTypeId", {
        is: (value: number) => [1, 3, 5].includes(value),
        then: yup.string().required("Required").nullable(),
        otherwise: yup.string().nullable(),
      })
      .trim(),
    timeActivateLimitStart: yup
      .string()
      .when("activationLimit", {
        is: true,
        then: yup.string().length(5, "Fill in the date field").required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
    timeActivateLimitEnd: yup
      .string()
      .when("activationLimit", {
        is: true,
        then: yup.string().length(5, "Fill in the date field").required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
    timeReceiveLimitStart: yup
      .string()
      .when("receiveLimit", {
        is: true,
        then: yup.string().length(5, "Fill in the date field").required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
    timeReceiveLimitEnd: yup
      .string()
      .when("receiveLimit", {
        is: true,
        then: yup.string().length(5, "Fill in the date field").required("Required"),
        otherwise: yup.string().nullable().default(undefined),
      })
      .trim(),
  })
  .required();

// Form Values Types
interface FormState {
  name: string;
  adminId?: IAccount["id"];
  contentTypeId: Content["id"];
  imageContentId?: ImageContent["id"];
  textContentId?: TextContent["id"];
  isPurchasable: boolean;
  price: number;
  totalQuantity: number;
  userReceivesOnReward: number;
  leftQuantity: number;
  timeActivateLimitStart?: string;
  timeActivateLimitEnd?: string;
  timeReceiveLimitStart?: string;
  timeReceiveLimitEnd?: string;
  receiveLimit?: boolean;
  activationLimit?: boolean;
  isTransferable: boolean;
}

// Page
export default function ContentUpdate() {
  const { user } = useAuth();
  const { id } = useParams();
  const { merchant } = useMerchantDetails();
  const { isLoading: adminsLoading } = useAdmins();
  const { isLoading: textContentLoading } = useTextContentList();
  const { isLoading: imageContentLoading } = useImageContentList();
  const { content } = useContent(Number(id));
  const { handleCreate } = useContentCreate();
  const { handleUpdate } = useContentUpdate(Number(id));
  const { handleDelete } = useContentDelete(Number(id));
  const { route } = useMerchantNavigation();

  const form = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: content || {
      name: "",
      contentTypeId: 1,
    },
  });

  const onSubmit = useCallback(
    async (input: FormState) => {
      const data = {} as ContentInput;
      if (merchant && input.userReceivesOnReward * input.totalQuantity > merchant?.balance) {
        showNotification({
          autoClose: 3000,
          title: "Warning!",
          message:
            "Note that, this reward will be unavailable in mobile application because, you have not enough money to pay for specified amount of reward!",
          color: "yellow",
        });
        return;
      }

      try {
        const activationTime = {
          startTime: `${input.timeActivateLimitStart}:00`,
          endTime: `${input.timeActivateLimitEnd}:00`,
        };
        const receiveTime = {
          startTime: `${input.timeReceiveLimitStart}:00`,
          endTime: `${input.timeReceiveLimitEnd}:00`,
        };

        Object.assign(data, {
          name: input.name,
          contentTypeId: input.contentTypeId,
          imageContentId: input.imageContentId,
          textContentId: input.textContentId,
          price: input.price,
          isPurchasable: input.isPurchasable,
          totalQuantity: input.totalQuantity,
          userReceivesOnReward: input.userReceivesOnReward,
          leftQuantity: input.leftQuantity,
          activationLimit: input.activationLimit,
          receiveLimit: input.receiveLimit,
          isTransferable: input.isTransferable,
        });

        if (input.activationLimit) data.activationTime = activationTime;
        if (input.receiveLimit) data.receiveTime = receiveTime;

        if (!content) {
          await handleCreate(data);
          console.log("CREATED CONTENT");
        }

        if (!!content) {
          await handleUpdate({ ...content, ...data }).then(() => form.reset());
          console.log("UPDATED CONTENT");
        }
      } catch (e) {
        console.log(e);
      }
    },
    [form, handleCreate, handleUpdate, content]
  );

  const convertTime = (time: string | undefined): string | undefined => {
    if (!time) return;
    let [hours, minutes] = time.split(":");
    let filteredTime = `${hours}:${minutes}`

    return filteredTime;
  };

  // Update Form Data
  useEffect(() => {
    const obj = {
      timeActivateLimitStart: convertTime(content?.activationTime?.startTime),
      timeActivateLimitEnd: convertTime(content?.activationTime?.endTime),
      timeReceiveLimitStart: convertTime(content?.receiveTime?.startTime),
      timeReceiveLimitEnd: convertTime(content?.receiveTime?.endTime),
    };

    form.reset({
      name: content?.name || "",
      contentTypeId: content?.contentTypeId || 1,
      imageContentId: content?.imageContentId || undefined,
      textContentId: content?.textContentId,
      price: content?.price,
      isPurchasable: content?.isPurchasable,
      totalQuantity: content?.totalQuantity,
      userReceivesOnReward: content?.userReceivesOnReward,
      leftQuantity: content?.leftQuantity,
      activationLimit: content?.activationLimit,
      receiveLimit: content?.receiveLimit,
      isTransferable: content?.isTransferable || false,
      ...obj,
    });
  }, [content, form, user]);

  if (
    (id && !content) ||
    adminsLoading ||
    textContentLoading ||
    imageContentLoading
  )
    return <ContentLoader />;

  return (
    <FormProvider {...form}>
      <Root onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={0}>
          <Group position="apart">
            <Group position="left">
              <BackLink to={`${route}/contents`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:arrow-left-12-filled"} />
                </span>
                <Text size="xl" weight={"bold"}>
                  Back to Contents
                </Text>
              </BackLink>
            </Group>
            <Group position="right">
              {!!content && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  onClick={handleDelete}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Delete
                </Button>
              )}
              {!content && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  component={Link}
                  to={`${route}/contents`}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                radius={"sm"}
                color={"teal"}
                variant={"filled"}
                loading={form.formState.isSubmitting}
              >
                {content ? "Update" : "Create"}
              </Button>
            </Group>
          </Group>

          <ContentEdit />
        </Stack>
      </Root>
    </FormProvider>
  );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
