import {
	Button,
	Group,
	Paper,
	Select,
	Stack,
	Text,
	Tooltip,
} from '@mantine/core';
import { Icon } from '@iconify/react';
import { useController, useFormContext } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { ConstantIds } from 'api/interfaces/settings';
import { useAppConfigConstants } from 'features/AppAccess/hooks/useAppConfigConstants';
import GameItem from './GameItem';
import { MiniGame } from 'api/interfaces/locationEvents';

// Component
export default function ContentSelect() {
	const { control } = useFormContext();
	const { field } = useController({ name: 'gamesList', control });
	const constantId = ConstantIds.MINI_GAMES;
	const { data: constants } = useAppConfigConstants();
	const gamesData = constants!.items.filter(i => i.id === constantId);
	const [selectId, setSelectId] = useState<MiniGame['id'] | undefined | null>(
		undefined
	);

	const contentList: any = useMemo(() => {
		return (field.value || [])
			.map((id: number) => {
				const content = gamesData[0].jsonValue.find(
					(item: MiniGame) => item.id === id
				);
				if (!content) return undefined;
				return content;
			})
			.filter((item: MiniGame | undefined) => !!item);
	}, [gamesData, field.value]);

	const contentsData = useMemo(() => {
		return gamesData[0].jsonValue
			.filter((i: MiniGame) => !field.value.includes(i.id))
			.map((content: MiniGame) => ({
				value: String(content.id),
				label: content.name,
			}));
	}, [gamesData, field]);

	const onRemove = useCallback(
		(inputId: MiniGame['id']) => {
			if (!field.value.includes(inputId)) return;

			const values = field.value.filter((id: MiniGame['id']) => inputId !== id);
			field.onChange(values);
		},
		[field]
	);

	const onAdd = useCallback(() => {
		if (!selectId) return;

		field.onChange([Number(selectId), ...field.value]);
		setSelectId(undefined);
	}, [field, selectId]);

	return (
		<Paper withBorder radius={'md'} style={{ overflow: 'hidden' }}>
			<Stack spacing={0}>
				{/* Header */}
				<Group grow p={'lg'} style={{ borderBottom: '1px solid #e9ecef' }}>
					<Group position='left' pr={30}>
						<Text size='md' weight='bold'>
							Choose game
						</Text>

						<Tooltip
							multiline
							width={220}
							withArrow
							label='You can choose any game from the list..'
						>
							<Icon icon='material-symbols:info-outline' />
						</Tooltip>
					</Group>
					<Stack>
						<SelectField
							placeholder='Pick one'
							searchable
							nothingFound='No options'
							data={contentsData}
							classNames={{ input: 'input-field' }}
							onChange={id => setSelectId(id)}
							value={String(selectId)}
							sx={{ width: '100%', maxWidth: 300 }}
						/>
						<Button
							leftIcon={<Icon icon={'fluent:add-12-filled'} height={18} />}
							radius={'sm'}
							color={'dark'}
							variant={'filled'}
							onClick={onAdd}
							disabled={!selectId}
							sx={{ width: '100%', maxWidth: 300 }}
						>
							Add Content
						</Button>
					</Stack>
				</Group>

				{/* Content */}
				<Stack spacing={0}>
					{!contentList.length && (
						<Group grow p={'lg'}>
							<Text size='sm' color={'dimmed'}>
								Not found...
							</Text>
						</Group>
					)}
					{contentList.map((content: MiniGame) => (
						<GameItem
							key={Number(content.id)}
							content={content}
							onRemove={() => onRemove(content.id)}
						/>
					))}
				</Stack>
			</Stack>
		</Paper>
	);
}

// CSS Helper
const InputCSS = css`
	background: #fcfdfe;
	border: 1px solid #f0f1f7;
	color: #9fa2b4;
	outline: none;
	transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
		color 0.2s ease-in-out;

	&:hover,
	&:focus {
		color: #252733;
		border-color: #0095e5;
	}

	&::placeholder {
		color: rgb(75, 80, 109, 0.5);
	}
`;

const SelectField = styled(Select)`
	flex: 1;

	& .input-field {
		${InputCSS};
	}
`;
