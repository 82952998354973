import PhoneInputFromLib from "react-phone-input-2";
import {Stack, Text} from "@mantine/core";

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string
};

export default function PhoneInput({ value, onChange, disabled, error }: InputProps) {

  return (
      <Stack spacing={0}>
        <PhoneInputFromLib
            country={"us"}
            disabled={disabled}
            enableLongNumbers={true}
            value={value}
            onChange={onChange}
            inputClass={"phone-input"}
            buttonClass={"phone-dropdown-button"}
            dropdownClass={"phone-dropdown"}
        />
        {!!(error) && (
          <Text size={"xs"} color={'#fa5252'}>
            {error}
          </Text>
        )}
      </Stack>

  );
}
