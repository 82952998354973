import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Button,
  Center,
  Group,
  Image,
  Input,
  NumberInput,
  Select,
  Stack,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useServerConstants } from "hooks/useServerConstants";
import { Controller, useFormContext } from "react-hook-form";
import { useMerchantBalance } from "./hooks/useMerchantBalance";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { Icon } from "@iconify/react";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import PhoneInput from "../../components/PhoneInput";
import { useAuth } from "features/Auth/hooks/useAuth";
import { ErrorResponse } from "api/interfaces/api";
import {UserRoles} from "../../config";

// Component
export default function MerchantProfileEdit() {
  const { control, setError } = useFormContext();
  const { Country } = useServerConstants();
  const { setModalStatus, setupModal } = useModalStore();
  const { isPlatformAdmin } = useAuth();
  const { handleRefill } = useMerchantBalance();
  const {user, isMerchantSubscribed} = useAuth();

  const onRefillClick = async () => {
    if(user?.Role.id !== UserRoles.SUPER_ADMIN){
      if(!user?.Merchant?.isVerification){
        setupModal({
          type: "verification",
          content: "KYC Verification must be done",
        });
        setModalStatus(true);
        console.debug('kyc')
        return;
      }
      if(!isMerchantSubscribed){
        setupModal({
          type: 'error',
          content: 'You must buy a subscription before you top up your balance'
        })
        setModalStatus(true);
        return
      }
    }
    try {
      await handleRefill();
    } catch (e: any) {
      const error: ErrorResponse = e.response.data;
      setupModal({
        type: "error",
        content: error.error ?? "Something went wrong.",
      });
      setModalStatus(true);
    }
  };

  return (
    <Root>
      <Group grow position={"apart"}>
        <Stack>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label="Name"
                placeholder="Enter company name"
                variant="filled"
                required={!isPlatformAdmin}
                error={fieldState.error?.message}
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label="Email"
                placeholder="Enter company email address"
                variant="filled"
                error={fieldState.error?.message}
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field, fieldState}) =>
                <PhoneInput {...field} error={fieldState.error?.message}/>
          }
          />
          <Controller
            name="website"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label="Website"
                placeholder="Enter website address"
                variant="filled"
                // required
                error={fieldState.error?.message}
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />

          <Controller
            name="address"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label="Address"
                placeholder="Enter company address"
                variant="filled"
                // required
                error={fieldState.error?.message}
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />
        </Stack>

        <Stack h={"100%"}>
          <Controller
            name="logo"
            control={control}
            render={({ field, fieldState, formState }) => (
              <Input.Wrapper
                label="Logo"
                style={{ flex: 1 }}
                error={fieldState.error?.message}
                pos={"relative"}
                styles={{ error: { position: "absolute", bottom: 0 } }}
              >
                <Stack align={"center"} justify={"center"} h={"100%"}>
                  <Dropzone
                    p={0}
                    loading={formState.isSubmitting}
                    maxSize={1024 ** 2}
                    accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                    styles={{
                      root: {
                        width: 256,
                        height: 256,
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      },
                    }}
                    multiple={false}
                    onDrop={(files) => field.onChange(files[0])}
                    onReject={(e) => {
                      if (!e || !Array.isArray(e)) return;

                      e.forEach(({ errors }) =>
                        errors.forEach((err) => {
                          if (err.code !== "file-too-large") return;
                          setError("logo", {
                            message: "File larger than 1 mb",
                          });
                        })
                      );
                    }}
                  >
                    <Center
                      style={{
                        pointerEvents: "none",
                        padding: Boolean(field.value) ? 5 : 0,
                      }}
                    >
                      {Boolean(field.value) &&
                        typeof field.value === "object" && (
                          <Image
                            fit={"cover"}
                            styles={ImageStyles}
                            radius={"sm"}
                            src={URL.createObjectURL(field.value)}
                            alt="preview picture"
                          />
                        )}

                      {!Boolean(field.value) && (
                        <Stack spacing={0}>
                          <Icon
                            width={72}
                            color={"#00000070"}
                            icon="fa-solid:file-upload"
                          />
                        </Stack>
                      )}

                      {Boolean(field.value) &&
                        typeof field.value === "string" && (
                          <Stack spacing={0}>
                            <Image
                              fit={"cover"}
                              styles={ImageStyles}
                              radius={"sm"}
                              src={field.value}
                              alt="preview picture"
                            />
                          </Stack>
                        )}
                    </Center>
                  </Dropzone>
                </Stack>
              </Input.Wrapper>
            )}
          />
        </Stack>
      </Group>

      <Group grow>
        <Group>
          <Controller
            name="countryId"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                label="Country"
                placeholder="Pick country"
                variant="filled"
                searchable
                required={!isPlatformAdmin}
                data={Country.map((item) => ({
                  value: String(item.id),
                  label: item.name,
                }))}
                defaultValue={String(field.value)}
                error={fieldState.error?.message}
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />
        </Group>

        {user?.Role.id !== 4 &&
            (<Group align={"flex-end"}>
                <Controller
                    name="balance"
                    control={control}
                    render={({field}) => (
                        <NumberInput
                            label="Balance (Qubi coins)"
                            placeholder="0"
                            variant="filled"
                            required={!isPlatformAdmin}
                            hideControls
                            disabled
                            classNames={{input: "input-field"}}
                            {...field}
                        />
                    )}
                />
                <Button onClick={() => onRefillClick()}>Refill balance</Button>
            </Group>)
        }
      </Group>

      <Group grow>
        <Stack>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <Textarea
                label="Description"
                placeholder="Enter company description"
                variant="filled"
                required={!isPlatformAdmin}
                error={fieldState.error?.message}
                classNames={{ input: "input-field" }}
                styles={{ input: { minHeight: 200 } }}
                {...field}
              />
            )}
          />
        </Stack>
      </Group>
    </Root>
  );
}

// CSS Helper
const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

// Styling
const Root = styled(Stack)`
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  overflow-y: hidden;
  gap: 20px;
`;

const TextField = styled(TextInput)`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
const SelectField = styled(Select)`
  flex: 1;

  & .input-field {
    ${InputCSS};
    cursor: pointer;
  }
`;

const ImageStyles = {
  image: {
    width: "246px !important",
    height: "246px !important",
    borderRadius: "50%",
  },
};
