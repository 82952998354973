import styled from "@emotion/styled";
import { Grid, Stack, Text, Title } from "@mantine/core";
import { MerchantTransfer } from "api/interfaces/merchants";
import dayjs from "dayjs";
import { FC, useCallback } from "react";

interface TransferItemProps {
  transfer: MerchantTransfer;
}

export const TransferItem: FC<TransferItemProps> = ({ transfer }) => {
  console.log("🚀 ~ file: TransferItem.tsx:10 ~ transfer", transfer);

  const renderItem = useCallback(() => {
    const normalizeDate = dayjs(transfer.createdAt).format("DD-MM-YYYY ");
    const normalizeTime = dayjs(transfer.createdAt).format("HH:mm");
    if (transfer.toUserId) {
      const userEmail = transfer.toUser.email;
      return (
        <>
          <Stack spacing={0}>
            <Title order={5}>{userEmail}</Title>
            <Text color="gray" fz={14}>
              {`${normalizeDate} ${normalizeTime}`}
            </Text>
          </Stack>
          <Text color="red">-{transfer.amount}</Text>
        </>
      );
    }

    if (transfer.fromUserId) {
      const userEmail = transfer.fromUser.email;
      return (
        <>
          <Stack spacing={0}>
            <Title order={5}>{userEmail}</Title>
            <Text color="gray" fz={14}>
              {`${normalizeDate} ${normalizeTime}`}
            </Text>
          </Stack>
          <Text color="green">+{transfer.amount}</Text>
        </>
      );
    }

    if (transfer.fromMerchantId) {
      return (
        <>
          <Stack spacing={0}>
            <Title order={5}>{normalizeDate}</Title>
            <Text color="gray" fz={14}>
              {normalizeTime}
            </Text>
          </Stack>
          <Text color="red">-{transfer.amount}</Text>
        </>
      );
    }

    if (transfer.toMerchantId) {
      return (
        <>
          <Stack spacing={0}>
            <Title order={5}>{normalizeDate}</Title>
            <Text color="gray" fz={14}>
              {normalizeTime}
            </Text>
          </Stack>
          <Text color="green">+{transfer.amount}</Text>
        </>
      );
    }
  }, [transfer]);

  return (
    <Root justify="space-between" align="center">
      {renderItem()}
    </Root>
  );
};

const Root = styled(Grid)`
  flex-direction: row;
  overflow: auto;

  background-color: #ffffff;
  padding: 8px 16px;
  margin: 0;
  user-select: none;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
