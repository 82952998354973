import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider as ReduxProvider } from "react-redux";

import Routes from "features/Routes";
import store from "store";

import 'react-phone-input-2/lib/style.css'
import "assets/styles/global.css";
import { MantineProvider } from "@mantine/core";
import { ModalContainer } from "features/Modals";
import { AppVersion } from "components/AppVersion";
import { Notifications } from '@mantine/notifications';

const queryClient = new QueryClient();
const isDev = process.env.NODE_ENV === "development";

const tokenChange = (event:any) => {
  if(event.key === 'auth-token'){
    window.location.reload();
  }
}
window.addEventListener('storage', tokenChange,false)

export default function App() {
  return (
      <MantineProvider theme={{ fontFamily: 'Roboto' }}>
        <Notifications/>
        <QueryClientProvider client={queryClient}>
          <ReduxProvider store={store}>
            <AppVersion />
            <Routes />
            <ModalContainer/>
          </ReduxProvider>
          {isDev && <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />}
        </QueryClientProvider>
      </MantineProvider>
  );
}
