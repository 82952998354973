export enum UserRoles {
  SUPER_ADMIN = 1,
  QUBI_ADMIN = 2,
  SUPER_MERCHANT = 3,
  MERCHANT = 4,
}

export const appConfig = {
	debug: false,
	showVersion: false
}
