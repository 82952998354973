import { Button, Group, Stack, Text } from "@mantine/core";
import { Icon } from "@iconify/react";
import { KycForm } from "../../../features/Verifications/components/KycForm";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMerchantVerify } from "../../../features/Verifications/hooks/useMerchantVerify";
import * as yup from "yup";
import styled from "@emotion/styled";
import { useMerchantDetails } from "../../../features/MerchantProfile/hooks/useMerchantDetails";

const maxDateBirthday = new Date();
const minDateBirthday = new Date("1923-01-01");

const kycSchema = yup
  .object({
    firstName: yup
      .string()
      .matches(/^[A-Za-z]*$/, "Please enter valid name")
      .max(15, "First Name is too long - should be 15 chars maximum")
      .trim()
      .required("Required"),
    secondName: yup
      .string()
      .matches(/^[A-Za-z]*$/, "Please enter valid name")
      .max(15, "Last Name is too long - should be 15 chars maximum")
      .trim()
      .required("Required"),
    idNumber: yup
      .string()
      .trim()
      .matches(/^[A-Za-z0-9]*$/, "Please enter valid id number")
      .max(20, "Id number is too long - should be 20 chars maximum")
      .required("Required"),
    gender: yup.string().required("Required"),
    dateOfBirth: yup
      .date()
      .required("Required")
      .min(minDateBirthday, "Please enter correct date birthday")
      .max(maxDateBirthday, "Please enter correct date birthday")
      .typeError("Required")
      .nullable()
      .default(undefined),
    numberDocument: yup
      .string()
      .trim()
      .matches(/^[A-Za-z0-9]*$/, "Please enter valid document number")
      .required("Required"),
    typeDocument: yup.string().required("Required"),
    countryDocument: yup.string().required("Required"),
  })
  .required();

const veriffErrorCodes = {
  2103: { field: "typeDocument", message: "Document type is not supported" },
};

export function VerificationPage() {
  const { merchant } = useMerchantDetails();
  const { handleSubmit: verify, verifyOpen } = useMerchantVerify();

  const VerifyForm = useForm({
    resolver: yupResolver(kycSchema),
    defaultValues: {
      firstName: "",
      secondName: "",
      idNumber: "",
      gender: "",
      dateOfBirth: "",
      numberDocument: "",
      typeDocument: "",
      countryDocument: "",
    },
  });

  const onVerify = useCallback(
    async (form: any) => {
      try {
        await verify(form);
      } catch (e: any) {
        const errorCode = Number(
          e?.response?.data?.data?.code
        ) as keyof typeof veriffErrorCodes;
        const error = veriffErrorCodes[errorCode];

        if (!!error && typeof error === "object") {
          VerifyForm.setError(error.field as any, { message: error.message });
          return;
        }

        VerifyForm.setError("firstName", {
          message: "Something went wrong, try again later",
        });
      }
    },
    [verify, VerifyForm]
  );

  return (
    <Stack spacing={0}>
      <FormProvider {...VerifyForm}>
        <Form onSubmit={VerifyForm.handleSubmit(onVerify)}>
          <Stack id="verification" spacing={0}>
            <Group position="apart">
              <Group position="left">
                <Text size="xl" weight="bold">
                  Company Verification
                </Text>
              </Group>
              <Group position="right">
                {!merchant?.isVerification && !verifyOpen && (
                  <Button
                    type="submit"
                    leftIcon={<Icon icon="uil:comment-verify" height={"18"} />}
                    radius={"sm"}
                    color={"teal"}
                    variant={"filled"}
                    loading={VerifyForm.formState.isSubmitting}
                  >
                    Verify
                  </Button>
                )}
              </Group>
            </Group>
          </Stack>

          <KycForm
            isVerified={merchant?.isVerification}
            onVerification={merchant?.onVerification}
          />
        </Form>
      </FormProvider>
    </Stack>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
