import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { Content } from "api/interfaces/content";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useContentUpdate = (id?: Content["id"]) => {
  const queryClient = useQueryClient();

  const { id: merchantId } = useMerchantNavigation();
  // Event Update Handler
  const { mutateAsync: update, ...options } = useMutation((input: Content) =>
    api.contents.update(input, Number(merchantId))
  );

  // Handler for forms
  const handleUpdate = useCallback(
    async (input: Content) => {
      if (!id) return;

      try {
        await update({ ...input, id });

        await Promise.all([
          queryClient.invalidateQueries(["CONTENT_LIST", merchantId]),
          queryClient.invalidateQueries(["CONTENT"]),
        ]);

        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Content successfully updated!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [id, update]
  );

  return {
    handleUpdate,
    ...options,
  };
};
