import {useNavigate} from "react-router-dom";
import useMerchantSelectorStore from "../features/MerchantSelector/hooks/useMerchantSelectorStore";

export function useMerchantNavigation() {
	const navigate = useNavigate();
	const { useStore } = useMerchantSelectorStore();
	const merchantId = useStore(state => state.selected);
	
	const route = `/merchant/${merchantId}`
	
	return {
		id: merchantId,
		route,
		navigate: (path: string) => {
			const pathRoute = path.startsWith("/")
					? path
					: `/${path}`
			
			return navigate(`${route}${pathRoute}`)
		}
	}
}
