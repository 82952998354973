import { useMemo } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useServerConstants } from "hooks/useServerConstants";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import {
  Center,
  ColorInput,
  Input,
  Group,
  Image,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Switch,
} from "@mantine/core";

// Component
export default function ImageContentEdit() {
  const { control } = useFormContext();
  const { id } = useParams();
  const { ImageContentType } = useServerConstants();
  const overrideColor = useWatch({
    control,
    name: 'overrideQRColors',
  })
  const imageContentTypeId = useWatch({
    control,
    name: "imageContentTypeId",
  });

  const contentTypeList = useMemo(() => {
    if (ImageContentType.length === 0) return [""];

    return ImageContentType.map((content) => ({
      value: String(content.id),
      label: content.name,
    }));
  }, [ImageContentType]);

  return (
    <Root radius={"md"} withBorder p={"lg"}>
      <Group grow spacing={"lg"} align={"flex-start"}>
        <Stack spacing={"lg"}>
          {/* Name */}
          <Group grow>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Name"
                  placeholder="Enter content name"
                  variant="filled"
                  error={fieldState.error?.message}
                  required
                  classNames={{ input: "input-field" }}
                  {...field}
                />
              )}
            />
          </Group>
          {/* Preview */}
          <Group grow>
            {!!id && (
              <Controller
                name="url"
                control={control}
                render={({ field }) => (
                  <Input.Wrapper label={"Preview"} style={{ flex: 1 }}>
                    <Image
                      styles={{ image: { objectFit: "contain" } }}
                      fit={"contain"}
                      height={300}
                      src={field.value}
                      alt="preview picture"
                      withPlaceholder
                    />
                  </Input.Wrapper>
                )}
              />
            )}
            {!id && <div />}
          </Group>
        </Stack>

        <Stack spacing={"lg"}>
          {/* Content Type */}
          <Stack>
            <Controller
              name="imageContentTypeId"
              control={control}
              render={({ field }) => (
                <Input.Wrapper
                  id="content-type"
                  label="Image Type"
                  style={{ flex: 1 }}
                >
                  <SelectControl
                    id="content-type"
                    disabled={false}
                    data={contentTypeList}
                    fullWidth
                    {...field}
                    onChange={(value: string) => field.onChange(Number(value))}
                    value={String(field.value)}
                  />
                </Input.Wrapper>
              )}
            />
          </Stack>

          {/* QR CODE FORM */}
          {Number(imageContentTypeId) === 1 && (
            <Stack spacing={"lg"}>
              {/* QR TEXT */}
              <Group grow>
                <Controller
                  name="text"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="QR Text"
                      placeholder="Enter text"
                      variant="filled"
                      classNames={{ input: "input-field" }}
                      {...field}
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </Group>
              <Controller
                  name="overrideQRColors"
                  control={control}
                  render={({ field }) => (
                      <Switch
                          label="Override QR colors"
                          checked={field.value}
                          onChange={field.onChange}
                      />
                  )}
              />
              {/* QR CODE COLOR */}
              {overrideColor &&
                  <>
                    <Group grow>
                      <Controller
                          name="codeColor"
                          control={control}
                          render={({ field, fieldState }) => (
                              <ColorField
                                  label="QR Code Color"
                                  placeholder="Pick color"
                                  variant="filled"
                                  classNames={{ input: "input-field" }}
                                  error={fieldState.error?.message}
                                  {...field}
                              />
                          )}
                      />
                    </Group>
                    {/* QR BG COLOR */}
                    <Group grow>
                      <Controller
                          name="backgroundColor"
                          control={control}
                          render={({ field, fieldState }) => (
                              <ColorField
                                  label="QR Background Color"
                                  placeholder="Pick color"
                                  variant="filled"
                                  classNames={{ input: "input-field" }}
                                  error={fieldState.error?.message}
                                  {...field}
                              />
                          )}
                      />
                    </Group>
                  </>
              }
            </Stack>
          )}

          {/* IMAGE FORM */}
          {Number(imageContentTypeId) === 2 && (
            <Stack spacing={"lg"}>
              <Group grow>
                <Controller
                  name="image"
                  control={control}
                  render={({ field, fieldState, formState }) => (
                    <Input.Wrapper
                      label="Upload image"
                      style={{ flex: 1 }}
                      error={fieldState.error?.message}
                    >
                      <Dropzone
                        loading={formState.isSubmitting}
                        maxSize={3 * 1024 ** 2}
                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                        multiple={false}
                        onDrop={(files) => field.onChange(files[0])}
                        p={0}
                      >
                        <Center
                          style={{
                            pointerEvents: "none",
                            padding: Boolean(field.value) ? 0 : 16,
                          }}
                        >
                          {Boolean(field.value) && (
                            <Image
                              fit={"contain"}
                              styles={{ image: { objectFit: "contain" } }}
                              radius={"sm"}
                              src={URL.createObjectURL(field.value)}
                              alt="preview picture"
                            />
                          )}

                          {!Boolean(field.value) && (
                            <Stack spacing={0} align={"center"}>
                              <Text size="lg" inline>
                                Drag image here or click to select files
                              </Text>
                              <Text size="sm" color="dimmed" inline mt={8}>
                                Attach as many files as you like, supported formats: jpeg, png.
                                Each file should not exceed 10 MB
                              </Text>
                            </Stack>
                          )}
                        </Center>
                      </Dropzone>
                    </Input.Wrapper>
                  )}
                />
              </Group>
            </Stack>
          )}
        </Stack>
      </Group>
    </Root>
  );
}

// CSS Helper
const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling
const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
`;

const TextField = styled<any>(TextInput)`
  ${FieldCSS};
  position: relative;
  .mantine-TextInput-error {
    position: absolute;
    left: 0;
    bottom: -24px;
  }
  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;

const SelectControl = styled(SegmentedControl)`
  ${FieldCSS};
`;

const ColorField = styled(ColorInput)`
  ${FieldCSS};

  & .input-field {
    padding-left: 36px;
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;
