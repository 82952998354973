import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { EventInput } from "api/interfaces/events";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useEventCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { route, id: merchantId } = useMerchantNavigation();

  // Event Create Handler
  const { mutateAsync: create, ...options } = useMutation((input: EventInput) =>
    api.events.create(Number(merchantId), input)
  );

  // Handler for forms
  const handleCreate = useCallback(
    async (input: EventInput) => {
      try {
        const event = await create(input);
        navigate(`${route}/campaigns/${event.id}`);

        await Promise.all([
          queryClient.invalidateQueries(["EVENT_LIST", merchantId]),
          queryClient.invalidateQueries(["EVENT"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "The Event was successfully created",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [create, navigate]
  );

  return {
    handleCreate,
    ...options,
  };
};
