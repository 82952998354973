import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { Event, EventForm } from "api/interfaces/events";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useEventUpdate = (id?: Event["id"]) => {
  const queryClient = useQueryClient();
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  // Event Update Handler
  const { mutateAsync: update, ...options } = useMutation((values: EventForm) =>
    api.events.update(values, Number(selectedId))
  );

  // Handler for forms
  const handleUpdate = useCallback(
    async (input: EventForm) => {
      if (!id) return;

      //   const values: EventForm = {
      //     name: input.name,
      //     notes: input.notes,
      //     description: input.description,
      //     startDate: input.startDate,
      //     endDate: input.endDate,
      //     totalVisits: input.totalVisits,
      //     payToUser: input.payToUser,

      //     activationLimit: input.activationLimit,
      //     receiveLimit: input.receiveLimit,
      //   };

      //   if (input.activationLimit) values.activationTime = input.activationTime;
      //   if (input.receiveLimit) values.receiveTime = input.receiveTime;

      //   values.startDate = dayjs(
      //     dayjs(values.startDate).startOf("day")
      //   ).toString();
      //   values.endDate = dayjs(dayjs(values.endDate).startOf("day")).toString();

      try {
        await update({ ...input, id });

        await Promise.all([
          queryClient.invalidateQueries(["EVENT_LIST", selectedId]),
          queryClient.invalidateQueries(["EVENT", id]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "The Event was successfully updated",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [id, update]
  );

  return {
    handleUpdate,
    ...options,
  };
};
