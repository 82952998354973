import styled from "@emotion/styled";
import {
  Group,
  Input,
  Paper,
  SegmentedControl,
  Select,
  Stack,
  TextInput,
  NumberInput,
  Button,
} from "@mantine/core";
import { css } from "@emotion/react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useServerConstants } from "hooks/useServerConstants";
import { useCallback, useMemo } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { useMerchantNavigation } from 'hooks/useMerchantNavigation';
import { useLocationStore } from 'features/LocationsMap/hooks/useLocationStore';

// Component
export default function LocationEdit() {
  const { id } = useParams()
  const { control } = useFormContext();
  const { Country } = useServerConstants();
  const { route } = useMerchantNavigation()
  const countryId = useWatch({
    control,
    name: "countryId",
  });
  const { setLocationPossibility, setMapPath } = useLocationStore();
  const navigate = useNavigate();
  
  const country = useMemo(() => {
    return Country.find((i) => i.id === countryId) || Country[0];
  }, [countryId, Country]);
  
  const goToMap = useCallback(() => {
    navigate(`${route}/locations`);
    setLocationPossibility(true);
    setMapPath(true);
  }, [setLocationPossibility, setMapPath, navigate, route])

  return (
    <Root radius={"md"} withBorder p={"lg"}>
      <Group grow spacing={"lg"} align={'start'}>
        <Stack spacing={"lg"}>
          {/* Name */}
          <Group grow>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Name"
                  placeholder="Enter location name"
                  variant="filled"
                  error={fieldState.error?.message}
                  required
                  classNames={{ input: "input-field" }}
                  {...field}
                />
              )}
            />
          </Group>

          {/* Show Country */}
          <Group grow>
            <Controller
              name="countryId"
              control={control}
              render={({ field }) => (
                <SelectField
                  label="Country"
                  variant="filled"
                  disabled
                  data={[{ label: country.name, value: String(country?.id) }]}
                  classNames={{ input: "input-field" }}
                  {...field}
                  value={String(field.value)}
                  onChange={(value) => field.onChange(Number(value))}
                />
              )}
            />
          </Group>

          {/* Enabled */}
          <Group grow>
            <Controller
              name="isEnabled"
              control={control}
              render={({ field }) => (
                <Input.Wrapper
                  id="enabled-input"
                  label="Show in application"
                  style={{ flex: 1 }}
                >
                  <SelectControl
                    id="enabled-input"
                    data={[
                      { label: "Enabled", value: "true" },
                      { label: "Disabled", value: "false" },
                    ]}
                    fullWidth
                    {...field}
                    onChange={(value: string) =>
                      field.onChange(value === "true")
                    }
                    value={String(field.value)}
                  />
                </Input.Wrapper>
              )}
            />
          </Group>
  
          <Group grow>
            <Controller
                name="radiusMeters"
                control={control}
                render={({ field, fieldState }) => (
                    <NumberField
                        label="Radius in metres"
                        variant="default"
                        max={300}
                        hideControls
                        error={fieldState.error?.message}
                        classNames={{ input: "input-field" }}
                        {...field}
                    />
                )}
            />
          </Group>
        </Stack>
        <Stack spacing={"lg"}>
          {/* City */}
          <Group grow>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  label="City"
                  placeholder="Enter city name"
                  variant="filled"
                  required
                  classNames={{ input: "input-field" }}
                  {...field}
                />
              )}
            />
          </Group>
  
          {/* Address */}
          <Group grow>
            <Controller
              name="address"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Address in App"
                  placeholder="Enter address"
                  variant="filled"
                  error={fieldState.error?.message}
                  required
                  classNames={{ input: "input-field" }}
                  {...field}
                />
              )}
            />
          </Group>
          {/* Latitude */}
          <Group grow>
            <Controller
                name="latitude"
                control={control}
                render={({ field }) => (
                    <TextField
                        label="Latitude"
                        variant="filled"
                        disabled
                        classNames={{ input: "input-field" }}
                        {...field}
                    />
                )}
            />
          </Group>

          {/* Longitude */}
          <Group grow>
            <Controller
              name="longitude"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Longitude"
                  variant="filled"
                  disabled
                  classNames={{ input: "input-field" }}
                  {...field}
                />
              )}
            />
          </Group>
          
          <Group grow h={58} align={"end"}>
            {!id && (
              <Button
                radius={"sm"}
                color={"blue"}
                variant={"filled"}
                onClick={goToMap}
                >
                  Choose on map
              </Button>
            )}
          </Group>
        </Stack>
      </Group>
    </Root>
  );
}

// CSS Helper
const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling
const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
`;

const TextField = styled<any>(TextInput)`
  ${FieldCSS};
  position: relative;
  .mantine-TextInput-error {
    position: absolute;
    left: 0;
    bottom: -24px;
  }

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;

const SelectField = styled(Select)`
  ${FieldCSS};

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;

const SelectControl = styled(SegmentedControl)`
  ${FieldCSS};
`;


const NumberField = styled<any>(NumberInput)`
  ${FieldCSS};
  position: relative;
  .mantine-NumberInput-wrapper {
    margin: 0;
  }
  .mantine-NumberInput-error {
    position: absolute;
    left: 0;
    bottom: -17px;
  }

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;