import { Base } from "./Base";
import qs from "qs";
import { Event, EventForm, EventInput } from "./interfaces/events";
import {
  ApiResponse,
  ApiResponseList,
  ErrorResponse,
  OperationResponse,
  PaginationParams,
} from "./interfaces/api";
import { MerchantAnalytic } from "./interfaces/analytic";

export default class Events extends Base {
  async create(merchantId: number, input: EventInput) {
    const response = await this.request.post<ApiResponse<Event>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/events`,
      input
    );
    return response.data.data;
  }

  async readAll(
    merchantId: number,
    { skip = 0, limit = 1000 }: PaginationParams
  ) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponseList<Event>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/events?${query}`
    );
    return response.data.data;
  }

  async readOne(id?: Event["id"], merchantId?: number) {
    const response = await this.request.get<ApiResponse<Event>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/events/${id}`
    );
    return response.data.data;
  }

  async update({ id, ...data }: EventForm, merchantId: number) {
    const response = await this.request.patch<ApiResponse<Event>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/events/${id}`,
      data
    );
    return response.data.data;
  }

  async remove(id?: Event["id"], merchantId?: number) {
    try {
      const response = await this.request.delete<OperationResponse>(
        `${this.baseUrl}/admin/merchants/${merchantId}/events/${id}`
      );
      return response.data.isSuccess;
    } catch (e: any) {
      const error: ErrorResponse = await e.response.data;
      return error;
    }
  }

  async depositEvent(id: Event["id"], amount: number, merchantId: number) {
    const response = await this.request.post<ApiResponse<Event>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/events/${id}/deposit`,
      { amount }
    );

    return response.data.data;
  }

  async withdrawEvent(id: Event["id"], merchantId: number) {
    const response = await this.request.post<ApiResponse<Event>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/events/${id}/withdraw`
    );

    return response.data.data;
  }

  async getEventAnalytic(id?: Event["id"], merchantId?: number) {
    const response = await this.request.get<ApiResponse<MerchantAnalytic>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/events/${id}/analytics-iframe`
    );

    return response.data.data;
  }
}
