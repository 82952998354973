import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import { ActionIcon, Group, Stack, Title } from '@mantine/core';
import { MiniGame } from 'api/interfaces/locationEvents';

// Props
type ComponentProps = {
	content: MiniGame;
	onRemove: () => void;
};

// Component
export default function GameItem({ content, onRemove }: ComponentProps) {
	return (
		<Root grow>
			<Group position='left'>
				<Stack spacing={0}>
					<Title order={5} style={{ textTransform: 'capitalize' }}>
						{content.name}
					</Title>
				</Stack>
			</Group>

			<Group position='right' spacing={'lg'}>
				<Group position='right' spacing={'xs'}>
					<ActionIcon
						onClick={onRemove}
						variant='light'
						color={'red'}
						size={'lg'}
						radius={'md'}
						style={{ border: '1px solid #fbe7e7' }}
					>
						<Icon icon={'fluent:dismiss-12-filled'} />
					</ActionIcon>
				</Group>
			</Group>
		</Root>
	);
}

// Styling
const Root = styled(Group)`
	flex-direction: row;

	background-color: #ffffff;
	padding: 8px 16px;
	user-select: none;

	&:not(:last-child) {
		border-bottom: 1px solid #e9ecef;
	}

	& {
		transition-property: background-color;
		transition-duration: 250ms;
		transition-timing-function: ease-in-out;
	}

	&:hover {
		background-color: rgba(44, 62, 80, 0.05);
	}
`;
