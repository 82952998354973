import { Outlet } from "react-router-dom";
import Navbar from "features/Navbar";
import styled from "@emotion/styled";
import Header from "features/Header";
import { useUserInterfaceStore } from "features/UI/hooks/useUIStore";

// Layout
export default function DashboardLayout() {
  const { state } = useUserInterfaceStore()

  return (
    <DashboardRoot>
      {state.sidebar && (
        <Navbar />
      )}

      <AppContainer>
        {state.header && (
          <Header />
        )}
        <PageContainer headerSize={state.header ? 60 : 0}>
          <Outlet />
        </PageContainer>
      </AppContainer>
    </DashboardRoot>
  );
}

// Styling
const DashboardRoot = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  flex: 1;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`

const PageContainer = styled.div<{ headerSize: number}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: ${({ headerSize }) => `calc(100vh - ${headerSize}px)`};
`;
