// Component
import { useAuth } from "features/Auth/hooks/useAuth";
import styled from "@emotion/styled";
import { Badge, Button, Group } from "@mantine/core";
import { useAppConfigConstants } from "features/AppAccess/hooks/useAppConfigConstants";
import { useCallback } from "react";
import MerchantSelector from "../MerchantSelector";
import { useModalStore } from "features/Modals/hooks/useModalStore";

export default function Header() {
  const { user, isSuperAdmin } = useAuth();
  const { isLoading } = useAppConfigConstants();
  const { setModalStatus, setupModal } = useModalStore();
  const internalBalance = user?.internalBalance?.toFixed(3);

  const openFeedbackModal = useCallback(() => {
    setupModal({
      type: "feedback",
      content: "You can contact us via form or email.",
    });
    setModalStatus(true);
  }, [setupModal, setModalStatus]);

  return (
    <Root>
      <Group spacing={8}>
        <Button
          variant={"filled"}
          color={"violet"}
          onClick={openFeedbackModal}
          size="sm"
          loading={isLoading}
        >
          FEEDBACK
        </Button>
      </Group>

      <Group mx={"auto"}>
        <MerchantSelector />
      </Group>

      <Group spacing={10} noWrap>
        {isSuperAdmin && (
          <Badge size="xl" radius={"sm"}>
            Platform Balance: {internalBalance}
          </Badge>
        )}
        <Badge size="xl" variant="dot" color={"lime"} radius={"sm"}>
          {user?.Role.name}
        </Badge>
      </Group>
    </Root>
  );
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 60px;
  min-height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #e9ecef;
  padding: 8px 20px;
`;
