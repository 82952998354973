import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Group, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ContentLoader from "components/ContentLoader";
import ImageContentEdit from "features/ImageContentEdit";
import { useAuth } from "features/Auth/hooks/useAuth";
import { useAdmins } from "features/Admins/useAdmins";
import { useImageContent } from "features/ImageContentEdit/hooks/useImageContent";
import { useImageContentCreate } from "features/ImageContentEdit/hooks/useImageContentCreate";
import { useImageContentUpdate } from "features/ImageContentEdit/hooks/useImageContentUpdate";
import { useImageContentDelete } from "features/ImageContentEdit/hooks/useImageContentDelete";
import { ImageContent, ImageContentInput } from "api/interfaces/imageContents";
import { api } from "index";
import { BrowserQRCodeReader } from "@zxing/browser";
import { NotFoundException } from "@zxing/library";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";

// Validation Schema
const schema = yup
  .object({
    name: yup
      .string()
      .max(32, `Name is too long. Maximum length is 32 chars.`)
      .lowercase()
      .trim()
      .required("Required"),
    adminId: yup.number(),
    text: yup
      .string()
      .when("imageContentTypeId", {
        is: 1,
        then: yup.string().required("Required"),
        otherwise: yup.string(),
      })
      .trim(),
    codeColor: yup
      .string()
      .when("imageContentTypeId", {
        is: 1,
        then: yup.string().required("Required"),
        otherwise: yup.string(),
      })
      .lowercase()
      .trim(),
    backgroundColor: yup
      .string()
      .when("imageContentTypeId", {
        is: 1,
        then: yup.string().required("Required"),
        otherwise: yup.string(),
      })
      .lowercase()
      .trim(),
    image: yup.mixed().when(["imageContentTypeId", "url"], {
      is: 2 && !"" && !undefined,
      then: yup.mixed().required("Required"),
      otherwise: yup.mixed(),
    }),
  })
  .required();

// Form Values Types
interface FormState {
  type: "update" | "create";
  name: string;
  url: string;
  imageContentTypeId?: ImageContent["id"];
  image?: File;
  text: string;
  codeColor: string;
  backgroundColor: string;
  overrideQRColors: boolean;
}

// Page
export default function ImageContentUpdate() {
  const { user } = useAuth();
  const { id } = useParams();
  const { isLoading } = useAdmins();
  const { imageContent } = useImageContent(Number(id));
  const { handleCreate } = useImageContentCreate();
  const { handleUpdate } = useImageContentUpdate(Number(id));
  const { handleDelete } = useImageContentDelete(Number(id));
  const { route } = useMerchantNavigation();

  const form = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      type: id ? "update" : "create",
      name: "",
      url: "",
      imageContentTypeId: 1,
      text: "",
      codeColor: "#000000",
      backgroundColor: "#ffffff",
      overrideQRColors: false,
    },
  });

  const onSubmit = useCallback(
    async (input: FormState) => {
      const data = {} as ImageContentInput;

      try {
        // QR CONTENT
        if (input.imageContentTypeId === 1) {
          const response = await api.files.createQr({
            text: input.text,
            codeColor: input.overrideQRColors ? input.codeColor : "#000000",
            backgroundColor: input.overrideQRColors ? input.backgroundColor : "#ffffff",
          });

          const decoder = new BrowserQRCodeReader();
          const element = document.createElement("img");
          element.crossOrigin = "anonymous";
          element.src = response.url;
          await decoder.decodeFromImageElement(element);

          Object.assign(data, {
            name: input.name,
            url: response.url,
            imageContentTypeId: input.imageContentTypeId,
          });
        }

        // IMAGE CONTENT
        if (input.imageContentTypeId === 2 && input.image) {
          const response = await api.files.uploadImage(input.image);

          Object.assign(data, {
            name: input.name,
            url: response.url,
            imageContentTypeId: input.imageContentTypeId,
          });
        }

        if (!imageContent) {
          await handleCreate(data);
          console.log("CREATED QR CONTENT");
        }

        if (!!imageContent) {
          await handleUpdate({ ...imageContent, ...data });
          console.log("UPDATED QR CONTENT");
        }
      } catch (e: any) {
        if (e instanceof NotFoundException) {
          form.setError("backgroundColor", {
            message:
              "QR is not readable. Change the QR code color or QR background color",
          });
        }
        console.log("error", e);
      }
      finally {
        form.setValue('image', undefined)
      }
    },
    [form, handleCreate, handleUpdate, imageContent]
  );

  // Update Form Data
  useEffect(() => {
    if(!imageContent) return;

    form.reset({
      type: "update",
      name: imageContent.name,
      url: imageContent.url,

      imageContentTypeId: imageContent.imageContentTypeId || 1,
      text: "",
      codeColor: form.getValues("codeColor"),
      backgroundColor: form.getValues("backgroundColor"),
      overrideQRColors: form.getValues("overrideQRColors"),
    });

    async function setup() {
      let text = "";

      const decoder = new BrowserQRCodeReader();

      if (imageContent?.url) {
        const element = document.createElement("img");
        element.crossOrigin = "anonymous";
        element.src = imageContent.url;
        const content = await decoder.decodeFromImageElement(element);
        text = content.getText();
        form.setValue("text", text);
      }
    }
    if(!form.control._defaultValues.text && form.control._defaultValues.text === '') setup();
  }, [imageContent, form, id, user]);

  if ((id && !imageContent) || isLoading) return <ContentLoader />;

  return (
    <FormProvider {...form}>
      <Root onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={0}>
          <Group position="apart">
            <Group position="left">
              <BackLink to={`${route}/image-contents`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:arrow-left-12-filled"} />
                </span>
                <Text size="xl" weight={"bold"}>
                  Back to Image Contents
                </Text>
              </BackLink>
            </Group>
            <Group position="right">
              {!!imageContent && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  onClick={handleDelete}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Delete
                </Button>
              )}
              {!imageContent && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  component={Link}
                  to={`${route}/image-contents`}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                radius={"sm"}
                color={"teal"}
                variant={"filled"}
                loading={form.formState.isSubmitting}
              >
                {imageContent ? "Update" : "Create"}
              </Button>
            </Group>
          </Group>

          <ImageContentEdit />
        </Stack>
      </Root>
    </FormProvider>
  );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
