import {SettingsLayout} from "../../pages/platform/settings/SettingsLayout";
import {RichTextEditor} from "@mantine/rte";
import {useEffect} from "react";
import {Button, Stack, Text} from "@mantine/core";
import styled from "@emotion/styled";
import {useUpdateConstants} from "./hooks/useFaqUpdate";
import {ConstantIds} from "../../api/interfaces/settings";
import {useServerConstants} from "../../hooks/useServerConstants";
import { Controller, useForm } from "react-hook-form";

export const FaqSettings = () => {
  const {FaqValue} = useServerConstants()
  const {handleUpdateConstants, submitting} = useUpdateConstants()

  const form = useForm({
    defaultValues: {
      textValue: "",
    },
  });

  useEffect(() => {
    if (!FaqValue) return;

    form.reset({
      textValue: FaqValue,
    });
  }, [FaqValue, form]);

  return (
    <SettingsLayout>
      <Controller name="textValue" control={form.control} render={({ field }) => (
        <Stack mt={16} style={{gap: 16}}>
          <Text size="lg" weight="bold">FAQ</Text>

          {/*RICH TEXT*/}
          <TextEditor value={field.value} onChange={field.onChange} contentEditable controls={[
            ['bold', 'italic', 'underline', 'link',],
            ['unorderedList', 'orderedList', 'h1', 'h2', 'h3'],
            ['sup', 'sub'],
            ['alignLeft', 'alignCenter', 'alignRight'],
          ]} />

          {/*SUBMIT*/}
          <Stack align={'flex-start'}>
            <Button loading={submitting} onClick={() => handleUpdateConstants(ConstantIds.FAQ, field.value)}>Save</Button>
          </Stack>
        </Stack>
      )} />
    </SettingsLayout>
  )
}

const TextEditor = styled(RichTextEditor)`
  .quill {
    max-height: 410px;
    min-height: 200px;
    overflow: scroll;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

`
