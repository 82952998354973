import dayjs from "dayjs";
import { api } from "index";
import React from "react";
import {useMerchantDetails} from "../../MerchantProfile/hooks/useMerchantDetails";
import {MerchantVerifyParams} from "../../../api/interfaces/merchants";

export const useMerchantVerify = () => {
  const [verifyOpen, setVerifyOpen] = React.useState(false);
  const { merchant, refetch } = useMerchantDetails()

  const handleSubmit = React.useCallback(async (form: MerchantVerifyParams) => {
    form.dateOfBirth = dayjs(form.dateOfBirth).format("YYYY-MM-DD");
    
    const response = await api.merchants.verify(merchant?.id, form);

    const verifyWindow = window.open(response?.url, "_blank");
    const interval = setInterval(async () => {
        if (!verifyWindow?.closed) return;

        await refetch();
        clearInterval(interval);
        setVerifyOpen(false);
    }, 500);
    
    setVerifyOpen(true);
  }, [merchant, setVerifyOpen]);

  return {
    verifyOpen,
    handleSubmit,
  };
};
