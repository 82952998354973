import styled from "@emotion/styled";
import { Loader } from "@mantine/core";

// Component
export default function ContentLoader() {
	return (
		<LoaderContainer>
			<Loader size="md" sx={{ stroke: "#00a0ff" }} />
		</LoaderContainer>
	)
}

// Styling
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
